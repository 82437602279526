//制定目标
<template>
  <div class="enactTarget">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="568px"
    >
      <div class="examRule_head flex">
        <span>制定目标</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="enactTarget_main">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="目标类型:">
            <el-select v-model="form.type" placeholder="请选择目标类型">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="新增客户:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="新增房源:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="新增带看:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="新增约看:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="新增跟进:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="新增业绩:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-button round type="primary" @click="onSubmit">立即创建</el-button>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "",
        type:''
      }
    };
  },
  methods:{
    //打开
    open () {
      this.dialogVisible = true;
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //立即创建
    onSubmit () {
      this.dialogVisible = false;
      this.$message({
        message: '恭喜你，这是一条成功消息',
        type: 'success'
      });
    }
  }
};
</script>


<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.enactTarget_main {
  padding: 30px 44px 40px 44px;
  /deep/ .el-select {
    width: 100%;
  }
  /deep/ .el-button  {
    width: 100%;
  }
}
</style>
