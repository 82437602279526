//业绩目标
<template>
  <div class="performance">
    <div class="performance_box">
      <ul class="performance_head flex">
        <li>
          <div class="examRule_head">
            <span>今日目标</span>
          </div>
          <div class="performance_head_main flex flex-wrap">
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
          </div>
          <div class="examRule_head_footer flexs m-t-30">
            <img src="" alt="" />
            <span>不要气馁，再接再厉加油吧~</span>
          </div>
        </li>
        <li>
          <div class="examRule_head">
            <span>本月目标</span>
          </div>
          <div class="performance_head_main flex flex-wrap">
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
            <div class="performance_head_li">
              <div class="performance_head_num center">
                <span>10</span>
                <span>/16</span>
              </div>
              <div class="examRule_head_txt center">新增约看</div>
            </div>
          </div>
          <div class="examRule_head_footer flexs m-t-30">
            <img src="" alt="" />
            <span>恭喜您，本月目标已完成~</span>
          </div>
        </li>
      </ul>
      <div class="performance_main p-l-20 p-r-20 b-c-f m-t-20 p-t-30">
        <div class="examRule_head flex">
          <span>区域总监</span>
          <ul class="examRule_head_btn flexs">
            <li v-for="(item, index) in 2" :key="index" @click="changeTarget(index)">
              {{ index == 0 ? "权限设置" : "制定目标" }}
            </li>
          </ul>
        </div>
        <ul class="performance_main_ul">
          <li v-for="(item, index) in 10" :key="index">
            <div class="performance_main_left flexs">
              <div class="performance_main_ul_img">
                <img src="" alt="" />
              </div>
              <div class="performance_main_ul_con">
                <div class="performance_main_ul_name flexs">
                  <span>刘欣欣</span>
                  <span>15898554125</span>
                  <span>17885412569</span>
                </div>
                <div class="performance_main_ul_id">工号：541256</div>
              </div>
            </div>
            <div class="performance_main_right">
              <div class="performance_main_right_ul flexs">
                <div
                  class="performance_main_right_li flexs"
                  v-for="(item, index) in 2"
                  :key="index"
                >
                  <img src="" alt="" />
                  <span>{{ index == 0 ? "查看业绩" : "查看下级" }}</span>
                </div>
              </div>
              <div class="performance_main_right_txt flexs m-t-10">
                <span>业绩进度：</span>
                <div class="line">
                  <div class="line_l"></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="paging">
          <el-pagination
            background
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 制定目标 S -->
    <enact-target ref="target"></enact-target>
    <!-- 制定目标 E -->
    <!-- 权限设置 S-->
    <jurisdiction-set ref="jurisdiction"></jurisdiction-set>
    <!-- 权限设置 E-->
  </div>
</template>

<script>
import EnactTarget from '../../components/EnactTarget.vue';
import JurisdictionSet from '../../components/JurisdictionSet.vue';
export default {
  components: { EnactTarget, JurisdictionSet },
  data() {
    return {
      page: 1, //分页
      total: 0, //总页数
      list: [], //列表
    };
  },
  methods:{
    changeTarget (index) {
      if (index == 0) this.$refs.jurisdiction.open();
      else this.$refs.target.open();
    }
  }
};
</script>

<style lang="less" scoped>
.performance_head {
  li {
    width: 480px;
    padding: 30px 20px;
    background: #ffffff;
    .examRule_head {
      height: auto;
      padding-left: 14px;
      background: #ffffff;
      span {
        &::before {
          height: 20px;
        }
      }
    }
    .performance_head_li {
      width: 33.3%;
      margin-top: 40px;
      flex-direction: column;
    }
    .performance_head_num {
      margin-bottom: 5px;
      span {
        color: #ff2121;
        font-size: 20px;
        font-weight: bold;
        &:last-child {
          color: #333333;
        }
      }
    }
    .examRule_head_txt {
      color: #666666;
      font-size: 16px;
    }
    .examRule_head_footer {
      height: 48px;
      padding: 0 16px;
      background: #fffaf0;
      border-radius: 4px;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        color: #ffa914;
        font-size: 16px;
        margin-left: 15px;
      }
    }
    &:last-child {
      .examRule_head_footer {
        background: #ffecec;
        span {
          color: #ff5151;
        }
      }
    }
  }
}
.performance_main {
  .examRule_head {
    height: auto;
    padding-left: 14px;
    background: #ffffff;
    span {
      font-weight: bold;
      &::before {
        height: 20px;
      }
    }
  }
  .examRule_head_btn {
    li {
      width: 100px;
      height: 36px;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      line-height: 36px;
      border-radius: 4px;
      &:first-child {
        color: #3273f6;
        border: 1px solid #3273f6;
      }
      &:last-child {
        color: #ffffff;
        margin-left: 20px;
        background: #3273f6;
      }
    }
  }
}
.performance_main_ul {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 15px;
    border-bottom: 1px solid #ebebeb;
    .performance_main_ul_img {
      margin-right: 15px;
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
    }
    .performance_main_ul_con {
      height: 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .performance_main_ul_id {
        color: #666666;
        font-size: 14px;
      }
      .performance_main_ul_name {
        span {
          color: #333333;
          font-size: 16px;
          &:first-child {
            width: 100px;
          }
          &:nth-child(2) {
            margin-right: 20px;
          }
          &:last-child {
            color: #3273f6;
          }
        }
      }
    }
    .performance_main_right_li {
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      span {
        color: #ff752a;
        font-size: 14px;
      }
      &:last-child {
        margin-left: 30px;
        span {
          color: #3273f6;
        }
      }
    }
    .performance_main_right_txt {
      justify-content: flex-end;
      span {
        color: #666666;
        font-size: 14px;
        margin-right: 10px;
      }
      .line {
        width: 120px;
        height: 8px;
        position: relative;
        background: #EBEBEB;
        border-radius: 4px;
        .line_l {
          width: 30%;
          height: 8px;
          left: 0;
          position: absolute;
          background: #3273F6;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
